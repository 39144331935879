<template>
    <div class="right_main">
        <!-- 页面标题  按钮部分 -->
        <div class="top_btn">
            <!-- 页面标题部分 -->
            <div class="page_name">
                <div class="sig"></div>
                <div class="sig_name">机构列表</div>
            </div>
            <!-- 按钮部分 -->
            <div class="btn_cl">
            </div>
        </div>
        <!--表格部分  -->
        <div class="tab_list">
            <el-table :data="tableData" style="width: 100%"
                :header-cell-style="{color:'#000000',font:'14px 600',borderBottom:'none'}">
                <el-table-column prop="uid" align="center" v-if="show=false">
                </el-table-column>
                <el-table-column prop="partner_uid" label="机构名称" min-width="120">
                </el-table-column>
                <el-table-column prop="pcount" label="人数">
                </el-table-column>
                <el-table-column prop="untreated" label="未处理">
                </el-table-column>
                <el-table-column prop="treated" label="沟通中">
                </el-table-column>
                <el-table-column prop="complete" label="完成-续保">
                </el-table-column>
                <el-table-column prop="nocomplete" label="完成-不续保">
                </el-table-column>
                <el-table-column prop="customer_count" label="客户总量">
                </el-table-column>
                <el-table-column prop="month_count" label="新增客户总量">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <span @click="addtion(scope.row.uid,scope.row.partner_uid)">进入</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页部分 -->
        <div v-if="page_show">
            <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
        </div>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getSalesApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 表格数据
            tableData: [],
            // 分页
            page: 1,
            count: 0,
            max: 10,
            page_show: false,
            // 登录用户id
            user_id: '',
            // 是否有下级
            lower: '',
        }
    },
    created () {
        this.initData()
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            this.user_id = sessionStorage.getItem('id');
            this.lower = sessionStorage.getItem('lower')
            getSalesApi({
                page: this.page,
                auth_id: this.user_id,
                has_lower: this.lower
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    if (this.count <= 10) {
                        this.page_show = false;
                    } else {
                        this.page_show = true;
                    }
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' });
                }
            })
        },
        // 点击进入按钮
        addtion (id, name) {
            sessionStorage.setItem('name', name);    //存储子公司名
            this.$router.push({ path: '/detail', query: { id: id } });  //存储当前公司id
        },
    },
}
  </script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #ffffff;
    // padding: 20px;
    //  页面标题  按钮部分
    .top_btn {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 24px;
        border-bottom: 1px solid #e8e9f1;
        // 页面标题部分
        .page_name {
            display: flex;
            align-items: center;
            .sig {
                border-radius: 2px;
                width: 4px;
                height: 16px;
                background: #6a8be8;
                margin-right: 6px;
            }
            .sig_name {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }
        }
        // 按钮部分
        .btn_cl {
            width: 10px;
        }
    }
    // 表格部分
    .tab_list {
        height: 540px;
        background: #ffffff;
        border-radius: 0 0 20px 20px;
        padding: 20px 40px;
        .el-table {
            border-bottom: none;
        }
        /deep/.el-table td,
        .el-table th.is-leaf {
            border-bottom: none;
        }
        /deep/.el-table--border::after,
        .el-table--group::after,
        .el-table::before {
            background: #ffffff;
        }
        // 操作按钮
        span {
            cursor: pointer;
        }
    }
}
</style>